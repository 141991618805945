import React, { useMemo } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Button, Container, Grid, Typography } from "@mui/material";

import { ToolTipWrapper } from "../../../../../Components/TooltipWrapper";
import { useContractFormContext } from "../../ContractsFormContext";
import ContractDiscountPeriod from "./components/ContractDiscountPeriod";
import { useDiscountPeriods } from "./useDiscountPeriods";

type Props = {
  isVendorContract?: boolean;
};

const GeneralContractDiscountStep = ({ isVendorContract }: Props) => {
  const { state } = useContractFormContext();
  const { discountPeriods, errors, handleAddPeriod, handlePeriodChange, handleDeletePeriod } =
    useDiscountPeriods(isVendorContract);

  const contractStartDate = isVendorContract ? state.vendorContract!.startDate : state.startDate;
  const contractEndDate = isVendorContract ? state.vendorContract!.endDate : state.endDate;

  const newDiscountPeriodDisabled = useMemo(
    () =>
      !!discountPeriods.length &&
      (discountPeriods[discountPeriods.length - 1].hasNoEndDate ||
        (!!contractEndDate && discountPeriods[discountPeriods.length - 1].endDate?.equals(contractEndDate))),
    [discountPeriods, contractEndDate]
  );

  return (
    <Container maxWidth="sm" data-cy="discount-step">
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <Typography variant="h4">Discount</Typography>
          <Typography variant="body2" color="text.secondary">
            Enter discounts chronologically
          </Typography>
        </Grid>
        {discountPeriods.map((discount, index) => (
          <Grid item xs={12} key={index}>
            <ContractDiscountPeriod
              errors={errors?.[index]}
              index={index}
              discount={discount}
              isLastPeriod={index === discountPeriods.length - 1}
              minDiscountDate={discountPeriods[index - 1]?.endDate ?? contractStartDate.startOf("day")}
              maxDiscountDate={contractEndDate}
              onChange={handlePeriodChange(index)}
              onDeleteDiscountPeriod={() => {
                handleDeletePeriod(index);
              }}
              isCommitment={state.isCommitment}
            />
          </Grid>
        ))}
        <Grid item>
          <ToolTipWrapper
            title={
              discountPeriods[discountPeriods.length - 1]?.hasNoEndDate
                ? "You can't add another discount period if the previous one has no end date"
                : ""
            }
            arrow
          >
            <Button
              startIcon={<AddIcon />}
              disabled={newDiscountPeriodDisabled}
              onClick={handleAddPeriod}
              data-cy={"add-discount-button"}
            >
              Add new discount period
            </Button>
          </ToolTipWrapper>
        </Grid>
      </Grid>
    </Container>
  );
};

export const ContractDiscountStep = () => <GeneralContractDiscountStep isVendorContract={false} />;
export const VendorContractDiscountStep = () => <GeneralContractDiscountStep isVendorContract={true} />;
