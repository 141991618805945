import { useCallback } from "react";

import { Container, Grid, Typography } from "@mui/material";

import { formatCurrency } from "../../../../../utils/common";
import { useContractFormContext } from "../../ContractsFormContext";
import { type CommitmentPeriodType, type VendorContract } from "../../types";
import { hasCommitmentPeriodDiscount } from "../../utils";
import { CommitmentPeriodsList } from "./components/CommitmentPeriodsList";

export const VendorCommitmentStep = () => {
  const { state, setState } = useContractFormContext();
  const { commitmentPeriods, startDate, endDate } = state.vendorContract ?? {};

  const handleCommitPeriodsChange = useCallback(
    (commitmentPeriods: CommitmentPeriodType[]) => {
      setState((prevState) => ({
        ...prevState,
        vendorContract: {
          ...(prevState.vendorContract as VendorContract),
          commitmentPeriods,
        },
      }));
    },
    [setState]
  );

  if (!state.vendorContract) {
    return null;
  }

  return (
    <Container maxWidth="sm" data-cy={"vendor-commitment-step"}>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid container justifyContent="flex-start">
          <Typography variant="h4" data-cy="total-commitment">
            Total Vendor Commitment:{" "}
            {formatCurrency(
              commitmentPeriods?.reduce((acc, cp) => acc + (cp.value ?? 0), 0),
              "USD"
            )}
          </Typography>
        </Grid>
        <Grid container spacing={1} pt={2}>
          <CommitmentPeriodsList
            commitmentPeriods={commitmentPeriods ?? []}
            contractEndDate={endDate!}
            contractStartDate={startDate!}
            disabled={state.isEditForbidden}
            onChange={handleCommitPeriodsChange}
            showDiscount={hasCommitmentPeriodDiscount(state.type)}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
