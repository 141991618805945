import React, { useMemo } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { type DateTime } from "luxon";

import { sanitizeKeepingLocalDate } from "../../../../../../utils/common";
import { type DiscountPeriodType } from "../../../types";

type Props = {
  index: number;
  discount: DiscountPeriodType;
  errors?: Record<string, boolean>;
  isLastPeriod: boolean;
  minDiscountDate: DateTime;
  maxDiscountDate: DateTime | null;
  isCommitment?: boolean;
  onChange: (property: keyof DiscountPeriodType, value: DateTime | number | boolean | null) => void;
  onDeleteDiscountPeriod: () => void;
};

const ContractDiscountPeriod = ({
  index,
  discount,
  isLastPeriod,
  minDiscountDate,
  maxDiscountDate,
  isCommitment,
  onChange,
  onDeleteDiscountPeriod,
  errors,
}: Props) => {
  const startDateError = useMemo(() => {
    if (!errors?.startDate) {
      return "";
    }

    if (discount.startDate <= minDiscountDate) {
      return "Enter a date after the previous period's end date";
    }
  }, [discount.startDate, errors?.startDate, minDiscountDate]);

  const endDateError = useMemo(() => {
    if (!errors?.endDate) {
      return "";
    }

    if (discount.startDate && discount.endDate && discount.startDate > discount.endDate) {
      return "End date must be after start date";
    }

    if ((!isLastPeriod || !discount.hasNoEndDate) && !discount.endDate) {
      return "End date is required";
    }
  }, [discount.endDate, discount.hasNoEndDate, discount.startDate, errors?.endDate, isLastPeriod]);

  return (
    <Grid container spacing={2} data-cy={`discount-period-${index}}`}>
      <Grid container item display="flex" alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" fontWeight={500}>
          Discount period {index + 1}
        </Typography>
        <Button startIcon={<DeleteIcon />} onClick={onDeleteDiscountPeriod} color="error">
          Delete period
        </Button>
      </Grid>

      <Grid item xs={12} sm={6}>
        <DatePicker
          label="Discount start date"
          value={discount.startDate}
          onChange={(newValue) => {
            if (newValue) {
              onChange("startDate", sanitizeKeepingLocalDate(newValue));
            }
          }}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              error={errors?.startDate}
              helperText={startDateError}
              data-cy={`discount-${index}-start-date`}
              required
            />
          )}
          minDate={minDiscountDate}
          maxDate={maxDiscountDate}
          inputFormat="dd LLL, yyyy"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DatePicker
          label="Discount end date"
          value={discount.endDate}
          onChange={(newValue) => {
            if (newValue) {
              onChange("endDate", sanitizeKeepingLocalDate(newValue));
            }
          }}
          disabled={discount.hasNoEndDate}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              required={!isLastPeriod}
              error={errors?.endDate}
              helperText={endDateError}
              data-cy={`discount-${index}-end-date`}
            />
          )}
          minDate={discount.startDate}
          maxDate={maxDiscountDate}
          defaultCalendarMonth={discount.startDate}
          inputFormat="dd LLL, yyyy"
        />
      </Grid>

      {!isCommitment && (
        <Grid item xs={12}>
          <Tooltip
            title={!isLastPeriod && "You must enter a discount end date, since you have several discount periods"}
            arrow
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={discount.hasNoEndDate}
                  onChange={(event) => {
                    onChange("hasNoEndDate", event.target.checked);
                  }}
                  color="primary"
                />
              }
              disabled={!isLastPeriod}
              label="Discount has no end date"
            />
          </Tooltip>
        </Grid>
      )}

      <Grid item xs={12}>
        <TextField
          label="Period discount"
          type="number"
          value={discount.discount}
          onChange={(e) => {
            onChange("discount", parseFloat(e.target.value));
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          inputProps={{ min: 0, max: 100 }}
          error={errors?.discount}
          fullWidth
          data-cy={`discount-${index}-discount`}
        />
      </Grid>
    </Grid>
  );
};

export default ContractDiscountPeriod;
