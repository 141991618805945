import {
  AssetTypeBetterCloud,
  AssetTypeGoogleCloud,
  AssetTypeGoogleCloudStandalone,
  AssetTypeGSuite,
  AssetTypeOffice365,
  AssetTypeZendesk,
  DoitRole,
} from "@doitintl/cmp-models";
import LeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import RightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import {
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

import { useDoitRoleCheck } from "../../../../../Components/hooks/useDoitRoles";
import { sanitizeKeepingLocalDate } from "../../../../../utils/common";
import { gsuiteOffers } from "../../const";
import { useContractFormContext } from "../../ContractsFormContext";
import { productSupportsCredits, showDiscountField } from "../../utils";
import { AssetsAutocomplete } from "../components/AssetsAutocomplete";
import { Credits } from "../components/Credits";
import { CustomerCommitmentRadioGroup } from "./components/CustomerCommitmentRadioGroup";

const dateWeekAgo = DateTime.now().minus({ week: 1 });

export const ContractStep = () => {
  const { state, handleChange, handleChangeDate, setState } = useContractFormContext();
  const isDoitContractAdmin = useDoitRoleCheck(DoitRole.ContractAdmin);
  const isDoitContractOwner = useDoitRoleCheck(DoitRole.ContractOwner);

  const handleChangeCredits = (credits) => {
    setState((prevState) => ({
      ...prevState,
      credits,
      errors: {
        ...prevState.errors,
        credits: false,
      },
    }));
  };

  return (
    <Container maxWidth="sm" data-cy="contract-step">
      <Grid container spacing={1}>
        <Grid container item xs={12}>
          <FormControl data-cy="contract-type-radio-buttons" disabled={state.isEditForbidden}>
            <Typography variant="subtitle1" fontWeight={500} mb={1}>
              Contract type
            </Typography>
            <RadioGroup onChange={handleChange("isAdvantage")}>
              <FormControlLabel
                checked={state.isAdvantage}
                value={true}
                control={<Radio data-cy="is-advantage-button" />}
                label="DoiT Advantage"
              />
              <FormControlLabel
                value={false}
                checked={!state.isAdvantage}
                control={<Radio data-cy="legacy-resold-button" />}
                label="Legacy Resold"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid container item xs={12}>
          <Grid item mt={1}>
            <CustomerCommitmentRadioGroup />
          </Grid>
          {[AssetTypeGSuite, AssetTypeOffice365, AssetTypeBetterCloud, AssetTypeZendesk].includes(state.type) && (
            <Grid item ml={4}>
              <FormControlLabel
                control={
                  <Switch
                    data-cy="renewal-switch"
                    checked={state.isRenewal}
                    value={state.isRenewal}
                    onChange={handleChange("isRenewal")}
                    color="primary"
                    disabled={state.isEditForbidden}
                  />
                }
                label="Renewal Contract"
              />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <DatePicker
            renderInput={(params) => (
              <TextField
                data-cy="commitment-period-start-date"
                helperText="Select the contract start date"
                margin="dense"
                fullWidth
                {...params}
                error={state.errors?.startDate}
              />
            )}
            minDate={!isDoitContractAdmin || !isDoitContractOwner ? sanitizeKeepingLocalDate(dateWeekAgo) : undefined}
            label="Start Date"
            value={state.startDate}
            onChange={handleChangeDate("startDate")}
            components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
            inputFormat="dd LLL, yyyy"
            disabled={state.isEditForbidden}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <DatePicker
            renderInput={(params) => (
              <TextField
                data-cy="commitment-period-end-date"
                margin="dense"
                helperText="Select the contract end date (exclusive)"
                fullWidth
                {...params}
                error={state.errors.endDate}
              />
            )}
            label="End Date"
            value={state.endDate}
            onChange={handleChangeDate("endDate")}
            disabled={(!state.isCommitment && !state.isSoftCommitment) || state.isEditForbidden}
            components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
            inputFormat="dd LLL, yyyy"
            minDate={state.startDate}
          />
        </Grid>

        {showDiscountField(state.type) && (
          <Grid container spacing={1} pl={1}>
            <Grid item xs={12} md={state.type === AssetTypeGoogleCloud ? 6 : 12}>
              <TextField
                fullWidth
                value={state.discount}
                data-cy="discount"
                onChange={handleChange("discount", true)}
                label="Discount"
                margin="dense"
                variant="outlined"
                type="number"
                helperText="Enter the contract discount percentage"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                disabled={state.isEditForbidden}
              />
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            fullWidth
            value={state.partnerMargin}
            onChange={handleChange("partnerMargin", true)}
            data-cy="partner-margin"
            label="Expected Partner Margin"
            margin="dense"
            variant="outlined"
            type="number"
            helperText="Enter the partner margin percentage (above 0)"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            disabled={state.isEditForbidden}
            error={state.errors.partnerMargin}
          />
        </Grid>

        {[AssetTypeGoogleCloud, AssetTypeGoogleCloudStandalone].includes(state.type) && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              data-cy="flexsave-discount-override"
              value={state.gcpFlexSaveOverwrite}
              onChange={handleChange("gcpFlexSaveOverwrite", true)}
              label="Flexsave Discount Override"
              margin="dense"
              variant="outlined"
              type="number"
              helperText="Discount applied to the DoiT fee only (1Y-3Y)"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              disabled={state.isEditForbidden}
            />
          </Grid>
        )}
        {state.type === AssetTypeGoogleCloud && (
          <>
            <Grid item xs={12}>
              <TextField
                fullWidth
                data-cy="rebase-modifier"
                value={state.rebaseModifier}
                onChange={handleChange("rebaseModifier", true)}
                error={state.errors.rebaseModifier}
                label="Rebase Modifier"
                margin="dense"
                variant="outlined"
                type="number"
                helperText="Enter rebase modifier percentage"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                disabled={state.isEditForbidden}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.discountPreemptible}
                    data-cy="discount-preemptible-switch"
                    value={state.discountPreemptible}
                    onChange={handleChange("discountPreemptible")}
                    color="primary"
                    disabled={state.isEditForbidden}
                  />
                }
                label="Apply Discount on Preemptibles"
              />
            </Grid>
            <Grid item xs={12}>
              <AssetsAutocomplete
                label="Billing Accounts"
                helperText="Restrict contract to assets or leave empty to include all assets"
                disabled={state.isEditForbidden}
              />
            </Grid>
          </>
        )}

        {state.type === AssetTypeGSuite && (
          <>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                data-cy="special-offers"
                label="Special Offers"
                helperText="Select Google Workspace special offer if applicable"
                margin="dense"
                variant="outlined"
                value={state.specialDiscount}
                onChange={handleChange("specialDiscount")}
                error={state.errors?.supportPlan}
                SelectProps={{
                  displayEmpty: true,
                  MenuProps: {
                    PaperProps: {
                      sx: { maxHeight: 100 },
                    },
                    MenuListProps: {
                      dense: true,
                    },
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={state.isEditForbidden}
              >
                <MenuItem value="" dense>
                  None
                </MenuItem>
                {gsuiteOffers.map(({ label, value }) => (
                  <MenuItem key={label} value={value} dense>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <AssetsAutocomplete
                label="Subscriptions"
                helperText="Restrict contract to assets or leave empty to include all assets"
                disabled={state.isEditForbidden}
              />
            </Grid>
          </>
        )}
      </Grid>

      {productSupportsCredits(state.type) && (
        <Credits
          isEditForbidden={state.isEditForbidden}
          values={state.credits}
          vendor={state.type}
          handleChangeCredits={handleChangeCredits}
          type="customerContract"
          errors={state.errors.credits}
        />
      )}
    </Container>
  );
};
