import { type JSX } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Skeleton, Stack, Tooltip, Typography } from "@mui/material";

import { NotAvailable } from "../Pages/Assets/Tabs/components/typographics";

export const cyIds = {
  field: "field",
  data: "data",
  infoIcon: "infoIconContainer",
  loading: "dataLoading",
};

export type CoupletProps = {
  field: string;
  value: string | JSX.Element | number;
  infoTooltip?: string;
  loading?: boolean;
  compact?: boolean;
  helperText?: string;
};

/**
 * Renders a data couplet in an accessible manner.
 * @param field the name of the field to be displayed
 * @param value the value of the field
 * @param infoTooltip optional tooltip text to be displayed when hovering over an info icon
 * @param loading whether or not the data is loading. Renders a skeleton in place of the data value if true.
 * @param compact whether or not to render the couplet in a compact manner.
 * @param helperText optional helper text to be displayed below the field name
 * @note for full accessibility, ContractDataCouplet should be wrapped in a `dl` element.
 */
const DataCouplet = ({ field, value, infoTooltip, loading, compact, helperText }: CoupletProps) => {
  const valueOutput = typeof value === "number" ? value || 0 : value || NotAvailable;
  return (
    <Stack direction="row">
      <Stack direction="column">
        <Typography
          variant="subtitle2"
          component="dt"
          width={compact ? "inherit" : "250px"}
          marginRight="1ch"
          data-cy={cyIds.field}
        >
          {field}
          {!!infoTooltip && (
            <Tooltip title={infoTooltip} arrow={true} placement="top">
              <Typography component="span" data-cy={cyIds.infoIcon}>
                <InfoOutlinedIcon color="action" fontSize="small" sx={{ mb: "-0.2rem", ml: "0.5ch" }} />
              </Typography>
            </Tooltip>
          )}
        </Typography>
        {!!helperText && (
          <Typography variant="caption" color="GrayText">
            {helperText}
          </Typography>
        )}
      </Stack>

      <Typography variant="body2" component="dd" data-cy={cyIds.data} width={compact ? "inherit" : "250px"} mt={0}>
        {loading ? <Skeleton data-cy={cyIds.loading} width="10ch" /> : valueOutput}
      </Typography>
    </Stack>
  );
};

export default DataCouplet;
