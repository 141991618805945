import { type Dispatch, type SetStateAction } from "react";

import { type ContractStateType } from "../../types";

export const validateDiscountStep = (
  state: ContractStateType,
  setState: Dispatch<SetStateAction<ContractStateType>>
) => {
  let isValid = true;
  const errors: Record<string, boolean>[] = [];

  for (const [i, discount] of state.discountPeriods.entries()) {
    errors.push({});
    if (discount.startDate < state.startDate) {
      isValid = false;
      errors[i].startDate = true;
    }

    if (state.endDate && discount.startDate > state.endDate) {
      isValid = false;
      errors[i].startDate = true;
    }

    if (discount.endDate && discount.startDate > discount.endDate) {
      isValid = false;
      errors[i].endDate = true;
    }

    if (state.endDate && discount.endDate && discount.endDate > state.endDate) {
      isValid = false;
      errors[i].endDate = true;
    }

    // Only last period can have null endDate
    if (!discount.endDate && i !== state.discountPeriods.length - 1) {
      isValid = false;
      errors[i].endDate = true;
    }

    if (!discount.endDate && !discount.hasNoEndDate) {
      isValid = false;
      errors[i].endDate = true;
    }

    if (i > 0 && discount.startDate < state.discountPeriods[i - 1].endDate!) {
      isValid = false;
      errors[i].startDate = true;
    }

    if (discount.discount === 0) {
      isValid = false;
      errors[i].discount = true;
    }
  }

  setState((prevState) => ({ ...prevState, errors: { ...prevState.errors, discountPeriods: errors } }));

  return isValid;
};
