import {
  type AssetTypeApigee,
  type AssetTypeAwsPpaDto,
  type AssetTypeAwsPpaEc2,
  type AssetTypeAwsPpaPinpoint,
  type AssetTypeAwsPpaS3,
  type AssetTypeAwsPpaSes,
  type AssetTypeAwsPpaShield,
  type AssetTypeBeyondCorp,
  type AssetTypeChronicleSecurity,
  type AssetTypeDoiTCloudIntelligence,
  type AssetTypeGoogleCloudAnthos,
  type AssetTypeGoogleCloudMandiant,
  type AssetTypeGoogleCloudPremiumSupport,
  type AssetTypeGoogleCloudWebRisk,
  type AssetTypeLookerStudioPro,
  type AssetTypeRecaptchaEnterprise,
  type AssetTypeSecurityCommandCenter,
  type AssetTypeVirusTotal,
  type CurrencyCode,
} from "@doitintl/cmp-models";
import { collection, type Reference, type Timestamp } from "@doitintl/models-types";

import { type AccountManagerModel } from "./AccountManager";
import { type AcceleratorType, type MasterPayerAccountsModel } from "./App";
import {
  type AssetModel,
  type AssetTypeAmazonWebServices,
  type AssetTypeAwsPpaCloudfront,
  type AssetTypeAwsSaasConsole,
  type AssetTypeAwsStandalone,
  type AssetTypeBetterCloud,
  type AssetTypeDoiTCloudNavigator,
  type AssetTypeDoiTCloudSolve,
  type AssetTypeDoiTCloudSolveSingleAccelerator,
  type AssetTypeGcpPartnerLedPremiumSupport,
  type AssetTypeGcpSaasConsole,
  type AssetTypeGoogleCloud,
  type AssetTypeGoogleCloudDirect,
  type AssetTypeGoogleCloudProject,
  type AssetTypeGoogleCloudProjectStandalone,
  type AssetTypeGoogleCloudStandalone,
  type AssetTypeGoogleGeolocationServices,
  type AssetTypeGSuite,
  type AssetTypeLooker,
  type AssetTypeMicrosoftAzure,
  type AssetTypeMicrosoftAzureStandalone,
  type AssetTypeOffice365,
  type AssetTypeZendesk,
} from "./Asset";
import { type CustomerModel } from "./Customer";
import { type EntityModel } from "./Entity";
import { type TierModel } from "./Tier";
import { type VendorContractModel } from "./VendorContract";

export type UpdatedBy = {
  email: string;
  name: string | null;
};

type ContractUpdates = {
  type: string;
  timestamp: Timestamp;
};

/**
 * Support levels for Google cloud contracts.
 * It only has one for now, but the architecture around support level has been designed to be extensible.
 */
export enum GooglePartnerSupportLevel {
  PARTNER_PREMIUM = "Partner-Led Premium Support",
}

@collection("contracts")
export class ContractModel {
  accountManager!: Reference<AccountManagerModel>;

  active!: boolean;

  assets?: Reference<AssetModel>[];

  plpsPercent?: number;

  commitmentPeriods?: CommitmentPeriod[];

  commitmentRollover?: boolean;

  contractFile!: ContractFile;

  credits?: ContractCredits;

  customer!: Reference<CustomerModel>;

  discount!: number;

  discountEndDate?: Timestamp;

  discountPeriods?: DiscountPeriod[];

  endDate!: Timestamp | null;

  entity!: Reference<EntityModel> | null;

  estimatedValue!: number;

  isCommitment!: boolean;

  isSoftCommitment!: boolean;

  isRenewal!: boolean;

  notes!: string;

  partnerMargin!: number;

  properties!: Properties | null;

  purchaseOrder!: string;

  startDate!: Timestamp;

  terminated!: boolean;

  timeCreated!: Timestamp;

  timestamp!: Timestamp;

  type!: ContractModelType;

  updatedBy?: UpdatedBy | string;

  vendorContract?: Reference<VendorContractModel>;

  subCollections?: {
    contractUpdates: ContractUpdates;
  };

  gcpContract?: Reference<ContractModel>[];

  isAdvantage?: boolean;

  pointOfSale?: PointOfSaleType;

  // DoiT Cloud Navigator/Solve
  tier?: Reference<TierModel>;

  commitmentMonths?: number; // if undefined, monthly automatic renewal

  currency?: CurrencyCode;

  paymentTerm?: SubscriptionPaymentTerm;

  chargePerTerm?: number;

  monthlyFlatRate?: number; // Solve only

  // DoiT Cloud Intelligence
  minimumServiceFee?: number;

  cloudSpendPercentage?: number;
}

export interface CommitmentPeriod {
  endDate: Timestamp;
  startDate: Timestamp;
  value: number;
  discount?: number;
}

export interface DiscountPeriod {
  startDate: Timestamp;
  endDate: Timestamp | null;
  discount: number;
}

export interface ContractFile {
  id: string;
  name: string;
  parentId: null | string;
  storage: null | string;
  url: string;
}

export interface ContractSupport {
  discount: number | null;
  flatRate: number | null;
  lastUpdateTime: Timestamp | null;
  mpaRef: Reference<MasterPayerAccountsModel> | null;
}

export type SkuData = {
  label: string;
  monthlyListPrice: number;
  googleSku: string;
};

export type LookerSku = {
  skuName: SkuData;
  months: number;
  quantity: number;
  monthlySalesPrice: number;
};

interface Properties {
  awsFlexSaveOverwrite?: number;
  discountPreemptible?: boolean;
  domains?: string[];
  flexRI?: number;
  freeUntil?: Timestamp;
  gcpFlexSaveOverwrite?: number | string;
  gcpSupport?: string;
  pricelistSheet?: ContractFile | null;
  rebaseModifier?: number;
  specialDiscount?: number | null;
  subscriptionFilter?: boolean[];
  supportPlan?: string;
  support?: Record<number, ContractSupport> | null;
  userEmail?: string;

  // looker
  skus?: LookerSku[];
  salesProcess?: string;
  invoiceFrequency?: number;
  contractDuration: number;

  // DoiT Cloud Accelerator
  typeContext?: Reference<AcceleratorType>;
  estimatedFunding?: number;
}

export type ContractModelType =
  | typeof AssetTypeAmazonWebServices
  | typeof AssetTypeAwsSaasConsole
  | typeof AssetTypeAwsStandalone
  | typeof AssetTypeAwsPpaCloudfront
  | typeof AssetTypeBetterCloud
  | typeof AssetTypeDoiTCloudNavigator
  | typeof AssetTypeDoiTCloudSolve
  | typeof AssetTypeGcpPartnerLedPremiumSupport
  | typeof AssetTypeGcpSaasConsole
  | typeof AssetTypeGoogleCloud
  | typeof AssetTypeGoogleCloudDirect
  | typeof AssetTypeGoogleCloudProject
  | typeof AssetTypeGoogleCloudProjectStandalone
  | typeof AssetTypeGoogleCloudStandalone
  | typeof AssetTypeGSuite
  | typeof AssetTypeLooker
  | typeof AssetTypeMicrosoftAzure
  | typeof AssetTypeMicrosoftAzureStandalone
  | typeof AssetTypeOffice365
  | typeof AssetTypeZendesk
  | typeof AssetTypeGoogleGeolocationServices
  | typeof AssetTypeDoiTCloudSolveSingleAccelerator
  | typeof AssetTypeDoiTCloudIntelligence
  | typeof AssetTypeApigee
  | typeof AssetTypeAwsPpaS3
  | typeof AssetTypeBeyondCorp
  | typeof AssetTypeChronicleSecurity
  | typeof AssetTypeGoogleCloudAnthos
  | typeof AssetTypeGoogleCloudPremiumSupport
  | typeof AssetTypeGoogleCloudWebRisk
  | typeof AssetTypeLookerStudioPro
  | typeof AssetTypeGoogleCloudMandiant
  | typeof AssetTypeSecurityCommandCenter
  | typeof AssetTypeVirusTotal
  | typeof AssetTypeRecaptchaEnterprise
  | typeof AssetTypeAwsPpaPinpoint
  | typeof AssetTypeAwsPpaEc2
  | typeof AssetTypeAwsPpaShield
  | typeof AssetTypeAwsPpaDto
  | typeof AssetTypeAwsPpaSes;

export type ContractCredits = Record<string, Credit>;

type Credit = {
  amount: number;
  description: string;
};

export type SubscriptionPaymentTerm = "annual" | "monthly";

export type ProductTypes = {
  label: string;
  value: string;
}[];

export type PointOfSaleType = "doit" | "aws-marketplace" | "gcp-marketplace";
